const links = [
  {
    label: '¿Bar o Restaurante?',
    path: '/',
    key: 'home',
    type: 'internal'
  },
  {
    label: '¿Cómo funciona?',
    path: '/como-funciona',
    key: 'como-funciona',
    type: 'internal'
  },
  {
    label: 'Demo gratuita',
    path: '/demo',
    key: 'demo',
    type: 'internal'
  },
  {
    label: 'Planes',
    path: '/planes',
    key: 'planes',
    type: 'internal'
  },
  {
    label: 'Contacto',
    path: '/contacto',
    key: 'contacto',
    type: 'internal'
  },
  {
    label: 'Login',
    path: 'https://barest.app/',
    key: 'login',
    type: 'external'
  },
];

export default links
