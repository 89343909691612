import images from '../../images'

const BigLoader = (): JSX.Element => {
  return (
    <div className="bigloader">
      <img src={images.barestBlue} alt="barest" />
      <p>Cargando...</p>
    </div>
  );
}

export default BigLoader;