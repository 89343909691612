import barestBlue from './files/barest-blue.svg';
import barestLogotipo from './files/barest-logotipo.svg';
import barestWhite from './files/barest-white.svg';
import facebook from './files/facebook.svg';
import instagram from './files/instagram.svg';
import linkedin from './files/linkedin.svg';
import appstore from './files/app-store.svg';
import googleplay from './files/google-play.svg';
import clock from './files/clock.svg';
import magnifier from './files/magnifier.svg';
import mark from './files/mark.svg';
import request from './files/request.svg';
import rows from './files/rows.svg';
import stripe from './files/stripe.svg';
import ticket from './files/ticket.svg';
import transfer from './files/transfer.svg';
import stars from './files/stars.svg';
import mahou from './files/mahou.svg';
import estrella from './files/estrella.svg';
import checkIcon from './files/check-icon.svg';
import closeIcon from './files/close-icon.svg';
import questionIcon from './files/question-icon.png';
import leftArrow from './files/left-arrow.svg';
import rightArrow from './files/right-arrow.svg';
import cocacola from './files/coca-cola.svg';
import playButton from './files/play-button.svg';
import youtube from './files/youtube.svg';

const icons = {
  appstore,
  barestBlue,
  barestLogotipo,
  barestWhite,
  facebook,
  instagram,
  linkedin,
  googleplay,
  clock,
  magnifier,
  mark,
  request,
  rows,
  stripe,
  ticket,
  transfer,
  stars,
  mahou,
  estrella,
  checkIcon,
  closeIcon,
  questionIcon,
  leftArrow,
  rightArrow,
  cocacola,
  playButton,
  youtube,
}

export default icons
