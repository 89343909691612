export const firstLinkGroup = [
  {
    label: 'Soporte',
    path: '/contacto',
    key: 'soporte',
  },
  {
    label: 'Aviso Legal',
    path: '/terminos-condiciones',
    key: 'avisolegal',
  },
  {
    label: 'Términos y condiciones',
    path: '/terminos-condiciones',
    key: 'terminos',
  },
  {
    label: 'FAQs',
    path: '',
    key: 'faqs',
  },
];

export const secondLinkGroup = [
  {
    label: 'Quienes somos',
    path: '/',
    key: 'quienessomos',
  },
  {
    label: 'Cómo funciona',
    path: '/como-funciona',
    key: 'comofunciona',
  },
  {
    label: 'Planes y precios',
    path: '/planes',
    key: 'planos',
  },
  {
    label: 'Solicitar Demo',
    path: '/demo',
    key: 'solicitardemo',
  },
];