
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { firstLinkGroup, secondLinkGroup } from './links';
import images from '../../images'

const Footer = (): JSX.Element => {
  return (
    <footer>
      <Container className="footer">
        <Row>
          <Col lg="5" className="footer__brand">
            <img src={images.barestBlue} alt="barest" />
            <p className="description">
              Descubre, reserva, pide, paga y &nbsp;<br/>
              comparte… <strong>todo desde la misma app.</strong>
            </p>
          </Col>
          <Col className="footer__section" xs="6" sm="4" lg="2">
            <p className="footer__section__title">
              Enlaces de
              <br />
              interés
            </p>
            <ul className="footer__section__options">
              {firstLinkGroup.map((link) => (
                <li key={link.key}>
                  <Link to={link.path}>
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col className="footer__section" xs="6" sm="4" lg="2">
            <p className="footer__section__title">
              Sobre <br />nosotros
            </p>
            <ul className="footer__section__options">
              {secondLinkGroup.map((link) => (
                <li key={link.key}>
                  <Link to={link.path}>
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col className="footer__section" xs="12" sm="4" lg="2">
            <p className="footer__section__title">
              Conecta con<br />nosotros en RRSS
            </p>
            <a href="https://www.facebook.com/profile.php?id=100070726788881" className="footer__section__icon" target="_blank" rel="noreferrer">
              <img
                src={images.facebook}
                alt="barest facebook"
                width="27"
                height="27"
                className="animated-scale"
              />
            </a>
            <a href="https://www.instagram.com/thebarestapp/?hl=es" className="footer__section__icon" target="_blank" rel="noreferrer">
              <img
                src={images.instagram}
                alt="barest instagram"
                width="27"
                height="27"
                className="animated-scale"
              />
            </a>
            <a href="https://www.linkedin.com/company/barest-app/about/" className="footer__section__icon" target="_blank" rel="noreferrer">
              <img
                src={images.linkedin}
                alt="barest linkedin"
                width="27"
                height="27"
                className="animated-scale"
              />
            </a>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <span className="text-dimmed">
              Copyright 2021. Todos los derechos resevados a Search Engine Business S.L.  
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
