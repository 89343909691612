import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import BigLoader from '../components/BigLoader';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

// Views (lazy load)
const Home = lazy(() => import('../views/Home'));
const HowItWorks = lazy(() => import('../views/HowItWorks'));
const Demo = lazy(() => import('../views/Demo'));
const Plans = lazy(() => import('../views/Plans'));
const Contact = lazy(() => import('../views/Contact'));
const Terms = lazy(() => import('../views/Terms'));

function App() {
  return (
    <div>
      <Navbar />
      <Suspense fallback={<BigLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/como-funciona" element={<HowItWorks />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/planes" element={<Plans />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/terminos-condiciones" element={<Terms />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
