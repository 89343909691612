
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom';
import links from './links'
import images from '../../images'

const toggleElStyle = {
  background: 'white',
  borderRadius: 10,
  display: 'block',
  height: 2,
  marginTop: 4,
  width: 20,
}

const NavbarToggleEl = ():JSX.Element => {
  return (
    <>
      <span style={toggleElStyle}></span>
      <span style={toggleElStyle}></span>
      <span style={toggleElStyle}></span>
    </>
  );
}

const BarestNavbar = (): JSX.Element => {
  return (
    <header className="navbar__container">
      <Navbar expand="lg" className="navbar">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              alt="barest"
              src={images.barestWhite}
              width="165"
              height="32"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <NavbarToggleEl />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
              {links.map(link => (
                link.type !== 'external' ? (
                  <Nav.Link as={NavLink} to={link.path} key={link.key}>{link.label}</Nav.Link>
                ) : (
                  <a href={link.path} target="_blank" rel="noreferrer" className="nav-link" key={link.key}>{link.label}</a>
                )
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default BarestNavbar;
